<template>
  <div>
    <!-- 批量激活  -->
    <template>
      <el-button @click="cardAliveBatch(ctxInfo)" size="small" type="primary" :disabled="!canBatchActive">
        批量激活
      </el-button>

    </template>
    <!-- 批量启用  -->
    <template>
      <el-button @click="cardEnableBatch(ctxInfo)" size="small" type="primary">批量启用</el-button>
    </template>
    <!-- 批量禁用  -->
    <template>
      <el-button @click="cardDeadBatch(ctxInfo)" size="small" type="primary">
        批量禁用
      </el-button>
    </template>

    <!-- 导出卡密  -->
    <template>
      <el-button @click="exportCardKeys" size="small" type="primary" :loading="exportAllLoading">
        导出卡密
      </el-button>
      <span v-if="exportAllLoading">提示：正在导出<span id="spinner">...</span></span>
    </template>

    <!-- 操作记录  -->
    <template>
      <el-button @click="handleClickRecord" size="small" type="primary">
        操作记录
      </el-button>
    </template>

    <!-- 导入延期数据  -->
    <template>
      <el-button @click="importExpData" size="small" type="primary">
        导入延期数据
      </el-button>
    </template>

    <!-- 转移卡密  -->
    <template>
      <el-button @click="transferCardKey" size="small" type="primary">
        转移卡密
      </el-button>
    </template>

    <upload-excel-component defaultSize :on-success="excelSuccess" ref="excel-upload"
      style="position:absolute;left:-999px;top:-999px;" />

    <!-- 转移卡密 -->
    <el-dialog-x-dialog title="操作" :visible.sync="transferCardKeysFormVisible" width="35%">
      <el-form :model="transferCardKeysForm">
        <el-form-item label="请选择要挑选到的卡券">
          <el-select v-model="transferCardKeysForm.cardId" placeholder="套餐卡券选择" clearable filterable>
            <el-option v-for="card in cards" :key="card.id" :label="card.card_name" :value="card.id"></el-option>
          </el-select>

        </el-form-item>
        <el-form-item style="color:red;">提示：只有未激活状态的卡密才会真实转移到目标卡券中。</el-form-item>
        <el-form-item style="text-align:right">
          <el-button @click="transferCardKeysFormVisible = false" size="small">取 消</el-button>
          <el-button type="primary" size="small" @click="transferCardKeyConfirm">确 定</el-button>
        </el-form-item>
      </el-form>

    </el-dialog-x-dialog>

  </div>
</template>

<script>
import * as API_Card from "@/api/combo-card-shop/card";
import UploadExcelComponent from "@/components/UploadExcel";
import { Foundation } from "@/../ui-utils";
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import mixin from "../mixin";

export default {
  name: "sixButton",
  components: {
    EnTableLayout,
    UploadExcelComponent,
  },
  mixins: [mixin],
  props: ["ctxInfo"],
  data () {
    return {
      currentTab: "0",
      loading: false,
      id: null,
      // 查看操作记录
      recordFlag: false,
      recordTableData: "",
      recordinform: {
        page_size: 20,
        page_no: 1,
        total: 0,
        oper_type: "",
        key_word: "",
        log_id: "", // 生成记录
        provide_log_id: "", // 发放记录
      },
      exportAllLoading: false,
      exportAllIssueLoading: false,
      cancelActivatedAllLoading: false,

      transferCardKeysFormVisible: false,
      transferCardKeysForm: {
        cardId: '',
      },
      importExpCardKeysParams: {
        card_id: this.$route.params.card_id,
        cards_key_dolist: [],
      },
      cards: [],
      canBatchActive: true,
    };
  },
  mounted () {
    this.Get_Cards();
  },
  watch: {
    ctxInfo: function (n, o) {
      if (n.idsList.length > 0 || n.is_all) {
        this.canBatchActive = false;
      } else {
        this.canBatchActive = true;
      }
    }
  },
  methods: {
    /**
     * 导出卡密
     */
    exportCardKeys () {
      this.exportAllLoading = true;
      if (this.ctxInfo.is_all === 0 && this.ctxInfo.idsList.length === 0) {
        this.exportAllLoading = false;
        return this.$message.error("请选择导出的卡密");
      }
      let _params = this.genCtxParams(this.ctxInfo);

      API_Card.exportOut(_params)
        .then((res) => {
          this.save(res);
        })
        .finally((_) => {
          this.exportAllLoading = false;
        });
    },
    // 导出全部
    ExportGetrecordList2 () {
      this.exportAllIssueLoading = true;
      let obj = {};
      obj.card_id = this.CarmiTableParams.card_id;
      obj.log_id = this.CarmiTableParams.log_id;
      obj.provide_log_id = this.CarmiTableParams.provide_log_id;
      obj.page_no = 0;
      obj.page_size = 0;

      API_coupon.getCardsKeyOperationLog(obj)
        .then((res) => {
          this.exportAllIssueLoading = false;
          const json = {
            sheet_name: "操作日志",
            sheet_values: res.data.map((item, idx) => ({
              编号: idx + 1,
              操作时间: Foundation.unixToDate(item.create_time),
              操作类型: item.oper_type === 1 ? "禁用" : "激活",
              卡号: item.card_code,
              卡密: item.card_key,
              操作人: item.opr_name,
              ip地址: item.ip,
            })),
          };
          this.MixinExportJsonToExcel(json, "操作日志");
        })
        .catch((_) => {
          this.exportAllIssueLoading = false;
        });
    },
    // 查看操作记录
    handleClickRecord () {
      this.$router.push({
        name: "recordListComboCard",
        query: {
          type: this.ctxInfo.params.sign,
          card_id: this.$route.params.card_id,
          cards_log_id: this.$route.params.log_id
        },
      });
    },
    /**
     * 转移卡密
     */
    transferCardKey () {
      this.transferCardKeysFormVisible = true;
    },
    /**
     * 转移卡密确认
     */
    transferCardKeyConfirm () {
      if (this.transferCardKeysForm.cardId) {
        if (this.ctxInfo.is_all === 0 && this.ctxInfo.idsList.length === 0) {
          return this.$message.error("请选择要转移的卡券");
        }
        let _params = this.genCtxParams(this.ctxInfo);
        API_Card.transferCardsKey({
          new_card_id: this.transferCardKeysForm.cardId,
          ..._params,
        }).then(() => {
          this.$message.success("转移成功");
          this.transferCardKeysFormVisible = false;
          this.transferCardKeysForm.cardId = '';
          _eventEmitter.emit('card-grant-list-active');
        });
      } else {
        this.$message.error("请选择转移到的卡券");
      }
    },
    Get_Cards () {
      API_Card.getCardList({ page_no: 1, page_size: 99999, card_type: 1 }).then(
        (res) => {
          this.cards = res.data;
        }
      );
    },
  },
};
</script>

<style lang="scss">
#spinner {
  display: inline-block;
  height: 1em;
  line-height: 1;
  text-align: left;
  vertical-align: -0.25em;
  overflow: hidden;
}

#spinner::before {
  display: block;
  content: "...\A..\A.";
  white-space: pre-wrap;
  /* 也可以是white-space: pre */
  animation: dot 3s infinite step-start both;
}

@keyframes dot {
  33% {
    transform: translateY(-2em);
  }

  66% {
    transform: translateY(-1em);
  }
}
</style>
